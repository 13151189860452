<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h1>Edit Licenses</h1>
        <b-button
          size="sm"
          type="button"
          variant="primary"
          v-b-modal.modal-create
        >
          Add New File
        </b-button>
      </div>
      <b-row align-content="center" v-if="settingDetails">
        <b-col cols="12">
          <div
            class="w-100 d-flex align-items-center justify-content-center"
            v-if="
              !settingDetails.documents || settingDetails.documents.length == 0
            "
          >
            <p class="text-center">No Licenses Available</p>
          </div>
          <div v-else>
            <b-row>
              <b-col
                @click="renderMediaModal(doc)"
                cols="12"
                md="3"
                class="my-1 cursor-pointer"
                v-for="doc in settingDetails.documents"
                :key="doc.priority"
              >
                <img src="/pdf.png" class="w-75 h-auto" alt="" />
                <p class="mt-1">{{ doc.imageTitle }}</p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-create"
      centered
      size="lg"
      ok-variant="success"
      title="Create New License"
      ok-title="Create"
      cancelTitle="Dismiss"
      @ok="pushNewAward"
    >
      <b-form-row>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="License Title" label-for="imageTitle">
              <b-form-input v-model="newDocDetails.imageTitle"></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="Document File" label-for="docFile">
              <b-form-file
                id="docFile"
                ref="refInputEl"
                accept=".pdf"
                placeholder="Choose File"
                @input="makeBase64Media"
              />
            </b-form-group>
          </b-form>
        </b-col>
      </b-form-row>
    </b-modal>
    <library-image-modal
      :tempMedia="tempDoc"
      mediaType="doc"
      @refetch="getSetting"
    ></library-image-modal>
  </b-overlay>
</template>
<script>
export default {
  title: "Licenses Setting",
  mounted() {
    this.getSetting();
  },
  data() {
    return {
      isLoading: false,
      settingDetails: null,
      newDocDetails: {
        base64: null,
        objectId: 1,
        type: 10, // 10 is for library
        priority: null,
        imageTitle: null,
        link: null,
        isWord: false,
      },
      tempDoc: null,
    };
  },
  methods: {
    renderMediaModal(doc) {
      this.tempDoc = {
        pictureUrl: doc.base64,
      };
      this.$bvModal.show("details-modal");
    },
    async sendCreateFileRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createFile = new CreateFile(_this);
        createFile.setRequestParamDataObj(_this.newDocDetails);
        await createFile.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Succesful",
                icon: "CheckIcon",
                variant: "success",
                text: "File Uploaded Successfully",
              },
            });
            _this.getSetting();
            _this.newDocDetails = {
              base64: null,
              objectId: 1,
              type: 10, // 10 is for library
              priority: null,
              imageTitle: null,
              link: null,
              isWord: false,
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async makeBase64Media() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0];
      _this.imagePreview = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.newDocDetails.base64 = result;
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        };
        fr.readAsDataURL(file);
      });
    },
    pushNewAward() {
      this.newDocDetails.priority =
        this.settingDetails.documents?.length > 0
          ? this.settingDetails.documents[
              this.settingDetails.documents.length - 1
            ].priority + 1
          : 1;
      this.sendCreateFileRequest();
    },
    async getSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASingleSetting = new GetASingleSetting(_this);
        getASingleSetting.setRequestParam({ id: 1 });
        await getASingleSetting.fetch((response) => {
          if (response.isSuccess) {
            _this.settingDetails = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
    LibraryImageModal,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { GetASingleSetting } from "@/libs/Api/Setting";
import { CreateFile } from "@/libs/Api/Media";
import LibraryImageModal from "@/views/components/utils/LibraryImageModal.vue";
import Helper from "@/libs/Helper";
</script>
